import React, { useState, useEffect } from 'react';
import SchoolSelector from '../components/SchoolSelector';
import UserForm from '../components/UserForm';
import TemplatePreview from '../components/TemplatePreview';
import ActionButtons from '../components/ActionButtons';
import { db } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Analytics } from '../utils/analytics';
import Comments from '../components/Comments';
import { statsRef } from '../services/firebase';
import { ClipboardIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import CountUp from 'react-countup';
import ServiceIntroduction from '../components/ServiceIntroduction';
import UsageGuide from '../components/UsageGuide';

// 상수로 분리
const INITIAL_STATS = {
  universities: 81,
  usage: 677  // 여기만 수정하면 됨
};

function Home() {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [formData, setFormData] = useState({});
  const [stats, setStats] = useState(INITIAL_STATS);
  const [copied, setCopied] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const statsDoc = await getDoc(statsRef);
        if (statsDoc.exists()) {
          const newStats = {
            ...INITIAL_STATS,
            usage: statsDoc.data().total || INITIAL_STATS.usage
          };
          setStats(newStats);
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };
    
    fetchStats();
  }, []);

  const handleSchoolSelect = async (schoolId) => {
    if (!schoolId) {
      setSelectedSchool(null);
      return;
    }

    try {
      const docRef = doc(db, 'universities', schoolId);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        console.error('School not found');
        setSelectedSchool(null);
        return;
      }

      const schoolData = {
        id: docSnap.id,
        ...docSnap.data()
      };
      setSelectedSchool(schoolData);
      
      // 학교 선택 이벤트 로깅
      Analytics.schoolSelected(schoolData.name, schoolData.id);
    } catch (error) {
      console.error('Error fetching school data:', error);
      setSelectedSchool(null);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText('myreportcover@gmail.com');
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // 2초 후 복사 상태 초기화
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleUrlCopy = async () => {
    try {
      await navigator.clipboard.writeText('https://myreportcover.com');
      setUrlCopied(true);
      setTimeout(() => setUrlCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };

  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto pt-8 sm:pt-12">
        <div className="text-center mb-8 sm:mb-12 px-2 sm:px-4">
          <h1 className="text-3xl font-bold text-gray-900">대학교 레포트 표지</h1>
          <p className="mt-2 text-sm text-gray-600">
            레포트표지를 간편하게 [ 출력 또는 다운로드(PDF) ] 할 수 있습니다.
          </p>
        </div>

        {/* 통계 타이틀 - 학교 선택 전에만 표시 */}
        {!selectedSchool && (
          <div className="text-center mb-12 px-2 sm:px-4" aria-live="polite">
            <p className="text-2xl sm:text-3xl text-gray-700 flex items-center justify-center gap-2 sm:gap-3">
              <span>국내 <CountUp 
                end={stats.universities} 
                duration={1.5}
                useGrouping={true}
                className="font-black text-3xl sm:text-5xl bg-gradient-to-r from-emerald-500 to-emerald-600 bg-clip-text text-transparent"
              /> 대학교의 레포트 표지가 <CountUp 
                end={stats.usage} 
                duration={1.5}
                useGrouping={true}
                className="font-black text-4xl sm:text-5xl bg-gradient-to-r from-blue-500 to-blue-600 bg-clip-text text-transparent mx-1"
              /> 회 사용되었습니다 🥳</span>
            </p>
          </div>
        )}

        <div className="max-w-sm mx-auto mb-10 sm:mb-14 px-2 sm:px-4">
          <SchoolSelector 
            value={selectedSchool?.id || ''} 
            onChange={handleSchoolSelect} 
          />
        </div>

        {/* 서비스 소개 & 사용 방법 섹션 - 학교 선택 전에만 표시 */}
        {!selectedSchool && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 px-4 mb-16">
            <ServiceIntroduction universitiesCount={stats.universities} />
            <UsageGuide />
          </div>
        )}

        {selectedSchool && (
          <>
            <div className="max-w-md mx-auto mb-8 sm:mb-12 px-2 sm:px-4 flex justify-center gap-4">
              <ActionButtons school={selectedSchool} formData={formData} />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-[auto,auto] lg:gap-6 lg:justify-center px-2 sm:px-4">
              <div className="lg:order-1 flex justify-center">
                <TemplatePreview school={selectedSchool} formData={formData} />
              </div>

              {selectedSchool.hasFields && (
                <div className="lg:order-2 w-full lg:w-[320px]">
                  <div className="bg-white rounded-lg shadow-sm border border-gray-200 px-4 py-6 sm:px-6">
                    <UserForm 
                      onSubmit={(data) => setFormData(data)} 
                      schoolName={selectedSchool.name}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="mt-12 text-center">
              <p className="text-lg font-medium mb-3">
                <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">우리 학교 친구들에게 공유해주세요</span>
                <span> 🙌🏻</span>
              </p>
              <div className="inline-flex items-center gap-2 bg-white/50 backdrop-blur-sm px-5 py-2.5 rounded-2xl shadow-sm border border-gray-200/60">
                <span className="text-gray-600 font-medium">https://myreportcover.com</span>
                <button
                  onClick={handleUrlCopy}
                  className="p-1.5 rounded-lg hover:bg-gray-50/80 active:bg-gray-100/80 transition-colors"
                  title="URL 복사"
                >
                  {urlCopied ? (
                    <ClipboardDocumentCheckIcon className="w-5 h-5 text-green-500" />
                  ) : (
                    <ClipboardIcon className="w-5 h-5 text-gray-400" />
                  )}
                </button>
              </div>
            </div>
          </>
        )}

        <div className="mt-20 bg-gray-200">
          <div className="pt-8 sm:pt-12">
            <Comments />
          </div>

          <div className="mt-12">
            <div className="py-6 text-center text-sm bg-gray-800 text-gray-300">
              <div className="flex items-center justify-center gap-2">
                문의하기:
                <a href="mailto:myreportcover@gmail.com" className="hover:text-white">
                  myreportcover@gmail.com
                </a>
                <button
                  onClick={handleCopy}
                  className="p-1.5 rounded-md hover:bg-gray-700 transition-colors"
                  title="이메일 주소 복사"
                >
                  {copied ? (
                    <ClipboardDocumentCheckIcon className="w-4 h-4 text-green-400" />
                  ) : (
                    <ClipboardIcon className="w-4 h-4" />
                  )}
                </button>
                <span className="mx-2">|</span>
                <a href="/privacy-policy" className="hover:text-white transition-colors">
                  개인정보처리방침
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;