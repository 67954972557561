import React from 'react';

function ServiceIntroduction({ universitiesCount = 70 }) {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 h-full px-6 py-10">   
      <h2 className="text-2xl font-bold text-center mb-8 bg-gradient-to-r from-emerald-500 to-emerald-600 bg-clip-text text-transparent">
        왜 만들었냐고요?
      </h2>
      
      <div className="space-y-8">
        {/* 주요 문제점 */}
        <div className="flex flex-col gap-4 items-center">
          <div className="text-center mb-2">
            <span className="text-4xl">📝</span>
          </div>
          <div>
            <blockquote className="text-lg italic text-gray-700 mb-3 text-center">
              "밤새 공들여 쓴 레포트… 그런데 표지가 별로라면?"
            </blockquote>
            <p className="text-gray-600 text-sm">
              저의 고민이었습니다. 그래서 학교 로고가 들어간 깔끔한 표지를 직접 만들어서 블로그에 올려뒀는데,
              언제부턴가 다른 학교 학생들이 댓글로 자신이 다니고 있는 학교의 레포트 표지 제작을 요청하기 시작했어요.
            </p>
          </div>
        </div>
        
        {/* 서비스 발전 */}
        <div className="flex flex-col gap-4 items-center">
          <div className="text-center mb-2">
            <span className="text-4xl">🚀</span>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2 text-center">블로그에서 서비스로</h3>
            <p className="text-gray-600 text-sm">
              시간이 흘러, AI의 도움을 받아서 레포트 표지를 만들어주는 서비스를 제작할 수 있게 되었어요.
              이제 학교별 레포트 표지 위에 학과, 학번, 이름이 기입된 상태로 깔끔하게 출력하고, PDF 파일로 다운로드 받을 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceIntroduction;