import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="max-w-3xl mx-auto px-4 py-12">
      <div className="bg-white shadow-sm rounded-lg p-6 sm:p-8">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">개인정보처리방침</h1>
          <p className="text-sm text-gray-500">시행일자: 2024.03.10</p>
        </div>

        <div className="space-y-6 text-gray-600">
          <section>
            <h2 className="text-lg font-semibold text-gray-900 mb-3">1. 개인정보 수집 항목 및 이용 목적</h2>
            <p>본 서비스는 레포트 표지 생성을 위해 어떠한 개인정보도 저장하지 않습니다. 사용자가 입력한 학교, 학과 등의 정보는 오직 레포트 표지 생성 시점에만 사용되며, 서버에 저장되지 않습니다.</p>
          </section>

          <section>
            <h2 className="text-lg font-semibold text-gray-900 mb-3">2. 자동 수집되는 정보</h2>
            <p>다음과 같은 정보가 자동으로 수집될 수 있습니다:</p>
            <ul className="list-disc pl-5 mt-2 space-y-1">
              <li>IP 주소</li>
              <li>브라우저 종류 및 버전</li>
              <li>접속 기기 정보</li>
              <li>방문 일시</li>
            </ul>
            <p className="mt-2">이 정보들은 서비스 이용 통계 분석 및 광고 제공 목적으로만 사용됩니다.</p>
          </section>

          <section>
            <h2 className="text-lg font-semibold text-gray-900 mb-3">3. 쿠키(Cookie) 사용</h2>
            <p>본 사이트는 Google AdSense를 통한 광고 서비스를 사용하고 있습니다. 이를 위해 Google에서 제공하는 쿠키가 사용될 수 있으며, 이는 보다 관련성 높은 광고를 제공하기 위한 목적으로만 사용됩니다.</p>
            <p className="mt-2">사용자는 브라우저 설정을 통해 쿠키 저장을 거부할 수 있습니다. 단, 이 경우 맞춤형 광고 제공이 제한될 수 있습니다.</p>
          </section>

          <section>
            <h2 className="text-lg font-semibold text-gray-900 mb-3">4. 개인정보의 제3자 제공</h2>
            <p>본 서비스는 사용자의 개인정보를 제3자에게 제공하지 않습니다. 단, Google AdSense 서비스 이용을 위해 Google의 광고 정책에 따른 정보 수집이 발생할 수 있습니다.</p>
          </section>

          <section>
            <h2 className="text-lg font-semibold text-gray-900 mb-3">5. 개인정보의 보유 기간</h2>
            <p>본 서비스는 사용자의 개인정보를 저장하지 않으므로, 별도의 보유 기간이 없습니다.</p>
          </section>

          <section>
            <h2 className="text-lg font-semibold text-gray-900 mb-3">6. 개인정보 보호책임자</h2>
            <p>이메일: myreportcover@gmail.com</p>
          </section>

          <section>
            <h2 className="text-lg font-semibold text-gray-900 mb-3">7. 개인정보처리방침의 변경</h2>
            <p>본 개인정보처리방침은 법령, 정책 또는 보안 기술의 변경에 따라 내용이 추가, 삭제 및 수정될 수 있습니다. 변경 시 웹사이트를 통해 공지하겠습니다.</p>
          </section>
        </div>

        <div className="mt-8 pt-6 border-t border-gray-200">
          <Link 
            to="/" 
            className="inline-flex items-center text-sm text-blue-600 hover:text-blue-500"
          >
            ← 홈으로 돌아가기
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;