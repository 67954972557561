import React from 'react';

function UsageGuide() {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 h-full px-6 py-10">   
      <h2 className="text-2xl font-bold text-center mb-8 bg-gradient-to-r from-blue-500 to-blue-600 bg-clip-text text-transparent">
        어떻게 사용하나요?
      </h2>
      
      <div className="space-y-6">
        <div className="flex items-start gap-4">
          <div className="flex-shrink-0 bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center text-blue-600 font-bold">
            1
          </div>
          <div>
            <h3 className="font-medium text-gray-800 mb-1">학교 선택하기</h3>
            <p className="text-gray-600 text-sm">
              상단의 학교 선택 필드에서 자신의 학교를 검색하거나 선택하세요.
            </p>
          </div>
        </div>
        
        <div className="flex items-start gap-4">
          <div className="flex-shrink-0 bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center text-blue-600 font-bold">
            2
          </div>
          <div>
            <h3 className="font-medium text-gray-800 mb-1">정보 입력하기</h3>
            <p className="text-gray-600 text-sm">
              학과, 학번, 이름 등 필요한 정보를 입력하세요. (선택사항이기 때문에 입력하지 않고도 이용할 수 있습니다.)
            </p>
          </div>
        </div>
        
        <div className="flex items-start gap-4">
          <div className="flex-shrink-0 bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center text-blue-600 font-bold">
            3
          </div>
          <div>
            <h3 className="font-medium text-gray-800 mb-1">미리보기 확인</h3>
            <p className="text-gray-600 text-sm">
              입력한 정보가 레포트 표지에 어떻게 표시되는지 실시간으로 확인할 수 있습니다.
            </p>
          </div>
        </div>
        
        <div className="flex items-start gap-4">
          <div className="flex-shrink-0 bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center text-blue-600 font-bold">
            4
          </div>
          <div>
            <h3 className="font-medium text-gray-800 mb-1">다운로드 또는 인쇄</h3>
            <p className="text-gray-600 text-sm">
              완성된 표지를 PDF로 다운로드하거나 바로 인쇄할 수 있습니다.
            </p>
          </div>
        </div>
        
        <div className="mt-6 text-center">
          <span className="inline-block px-4 py-2 bg-blue-50 text-blue-700 rounded-lg text-sm">
            ✨ 몇 번의 클릭만으로 완성! ✨
          </span>
        </div>
      </div>
    </div>
  );
}

export default UsageGuide; 