import React, { useEffect, useState, useMemo, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

function SchoolSelector({ value, onChange }) {
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const wrapperRef = useRef(null);

  // 현재 선택된 학교 정보
  const selectedUniversity = universities.find(uni => uni.id === value);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        setLoading(true);
        const querySnapshot = await getDocs(collection(db, 'universities'));
        const universitiesData = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            name: doc.data().name,
            hasFields: doc.data().hasFields
          }))
          .filter(university => university.name);

        setUniversities(universitiesData);
      } catch (err) {
        console.error('Error fetching universities:', err);
        setError('학교 목록을 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchUniversities();
  }, []);

  // 클릭 이벤트 감지하여 외부 클릭시 드롭다운 닫기
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // 검색어로 필터링된 대학교 목록
  const filteredUniversities = useMemo(() => {
    if (!searchTerm) {
      // 검색어가 없을 때는 전체 목록 반환
      return [...universities].sort((a, b) => 
        a.name.localeCompare(b.name, 'ko-KR')
      );
    }
    
    // 검색어가 있을 때는 기존 필터링 로직
    const searchTermLower = searchTerm.toLowerCase();
    return [...universities]
      .filter(uni => uni.name.toLowerCase().includes(searchTermLower))
      .sort((a, b) => a.name.localeCompare(b.name, 'ko-KR'));
  }, [universities, searchTerm]);

  // 키보드 네비게이션 처리
  const handleKeyDown = (e) => {
    if (!isOpen) {
      if (e.key === 'ArrowDown' || e.key === 'Enter') {
        setIsOpen(true);
        setSelectedIndex(0);
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        setSelectedIndex(prev => 
          prev < filteredUniversities.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        setSelectedIndex(prev => prev > 0 ? prev - 1 : prev);
        break;
      case 'Enter':
        if (selectedIndex >= 0 && selectedIndex < filteredUniversities.length) {
          handleSelect(filteredUniversities[selectedIndex]);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        setSelectedIndex(-1);
        break;
      default:
        break;
    }
  };

  const handleSelect = (university) => {
    onChange(university.id);
    setSearchTerm(university.name);
    setIsOpen(false);
    setSelectedIndex(-1);
  };

  if (loading) return <div>로딩중...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="w-full max-w-3xl" ref={wrapperRef}>
      <div className="space-y-1 bg-gradient-to-b from-blue-50 to-white p-6 rounded-xl shadow-lg border border-blue-100">
        <label htmlFor="university-search" className="block text-xl font-bold text-gray-900 mb-3">
          학교 선택 <span className="text-red-500">*</span>
        </label>
        <div className="relative">
          <input
            id="university-search"
            type="text"
            placeholder="학교 검색 또는 목록에서 선택"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setIsOpen(true);
              setSelectedIndex(-1);
            }}
            onFocus={() => setIsOpen(true)}
            onKeyDown={handleKeyDown}
            className="w-full px-4 py-4 rounded-lg border-2 border-blue-200 focus:border-blue-400 focus:ring-2 focus:ring-blue-100 transition-all shadow-sm hover:border-blue-300 text-lg"
          />
          {searchTerm && (
            <button
              onClick={() => {
                setSearchTerm('');
                onChange('');
              }}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
              type="button"
            >
              ✕
            </button>
          )}

          {/* 자동완성 드롭다운 */}
          {isOpen && (
            <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg border border-blue-100 max-h-60 overflow-auto">
              {filteredUniversities.length > 0 ? (
                <>
                  {!searchTerm && (
                    <div className="px-4 py-2 text-sm text-gray-500 border-b border-gray-100">
                      전체 {filteredUniversities.length}개 대학교
                    </div>
                  )}
                  <ul className="py-1">
                    {filteredUniversities.map((university, index) => (
                      <li key={university.id}>
                        <button
                          type="button"
                          onClick={() => handleSelect(university)}
                          className={`w-full text-left px-4 py-2.5 hover:bg-blue-50 ${
                            selectedIndex === index ? 'bg-blue-50 text-blue-700' : ''
                          }`}
                        >
                          {university.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <div className="px-4 py-3 text-sm text-gray-500">
                  검색 결과가 없습니다
                </div>
              )}
            </div>
          )}
        </div>

        {/* 선택된 학교 표시 */}
        {selectedUniversity && !searchTerm && (
          <div className="mt-3 px-4 py-3 bg-blue-50 rounded-lg text-blue-900 font-medium border border-blue-100">
            선택된 학교: {selectedUniversity.name}
          </div>
        )}
      </div>
    </div>
  );
}

export default SchoolSelector;